import frontendPackageData from '../../../../package.json';
// import backendPackageData from '../../../../package.json';
import { useAppStoreSelector } from '../../../store';
// TODO: Add a module declaration for the constants
// @ts-ignore
import { portal_version } from 'portal/dist/utils/constants';

function Copyright() {
  const settings = useAppStoreSelector((state) => state.settings);

  let footer = settings.data?.footer;
  const date = new Date().toLocaleDateString('en-CH').toString();
  const year = new Date().getFullYear().toString();

  footer = footer?.replace('__DATE__', date);
  footer = footer?.replace('__VERSION__', portal_version);
  footer = footer?.replace('__YEAR__', year);
  return (
    // <div className="text-secondary">
    <div
      style={{
        textAlign: 'center',
        fontSize: '12px',
      }}
      dangerouslySetInnerHTML={{__html: footer as string}}
    >
      {/* Frontend version: {frontendPackageData.version} Backend version:{' '}
            {settings.data?.backendVersion} */}
      {/* ADIB Portal {portal_version} | {'Copyright © '}
      <a href="https://www.conigon.com/">CONIGON GmbH</a>{' '}
      {new Date().getFullYear()}
      {'.'} */}
    </div>
  );
}

export default Copyright;
